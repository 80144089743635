/* Estilos gerais */
.card-container-modulos {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    border-radius: 25px;
    width: 300px;
    background-color: #8BB5E0;
}

.card-modulo-icon {
    width: 100px;
    height: 100px;
}

.card-modulo-label {
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4E1975;
    font-family: 'Roboto';
    font-weight: 600;
}

.card-modulo-acessar {
    height: 35px;
    width: 150px;
    border-radius: 25px;
    border: none;
    background-color: #4E1975;
    color: white;
}

.card-modulo-acessar:hover {
    background-color: #5e2785;
}

.card-container-modulos {
    /* Your existing styles */
    display: flex;
    align-items: center;
}

.card-modulo-icon {
    /* Your existing styles */
}

.card-modulo-label {
    /* Your existing styles */
    margin-right: 10px;
}

.card-modulo-acessar {
    position: relative;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #5e2785;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-modulo-acessar:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

/* .icon-block {
    display: inline-block;
    margin-left: 8px;
    font-size: 18px;
    color: #fff;
} */


/* Media queries para dispositivos móveis */
@media (max-width: 768px) {
    .card-container-modulos {
        width: 90%; /* Ajuste a largura para caber na tela menor */
        margin: 0.5rem; /* Ajusta a margem para telas menores */
        height: 250px; /* Mantém uma altura consistente */
    }

    .card-modulo-icon {
        width: 80px; /* Reduz o tamanho do ícone */
        height: 80px; /* Reduz o tamanho do ícone */
        margin-bottom: 1rem;
    }

    .card-modulo-label {
        font-size: 16px; /* Ajusta o tamanho da fonte para telas menores */
        height: auto; /* Ajusta a altura ao conteúdo */
        margin-bottom: 1rem;
    }

    .card-modulo-acessar {
        width: 150px; /* Reduz a largura do botão */
        height: 30px; /* Reduz a altura do botão */
    }
}
